/* eslint-disable @typescript-eslint/no-explicit-any */
export const hideProgressBar = () => {
	(window as any)?.appInterface?.hideProgressBar();
};
export const triggerWhatsApp = (phoneNumber: string | number, whatsappText: string) =>
	(window as any)?.appInterface?.openWhatsapp(phoneNumber, whatsappText);
export const triggerCallDialer = (phoneNumber: string | number) =>
	(window as any)?.appInterface?.openDialer(phoneNumber);
export const handleJobCardClick = (jobDetails: string) =>
	(window as any)?.appInterface?.openNextScreen(jobDetails);
export const finishActivity = () => {
	// It will simply dismiss webview container/screen
	(window as any)?.appInterface?.finishActivity();
};
export const trackAnalyticsEvent = (
	eventName: string,
	propsMap: any,
	analyticsTypes: Array<string>
) => {
	// analytics options - "FIREBASE", "MIXPANEL", "CLEVERTAP", "BRANCH"
	(window as any)?.appInterface?.trackAnalyticsEvent(
		eventName,
		JSON.stringify(propsMap),
		analyticsTypes
	);
};
export type AppUserData = {
	id?: string;
	accessToken?: string;
	language?: string;
	phoneNumber?: string;
};
export const getAppUserData = (): AppUserData | {} => {
	// Check if appInterface and getUser function exist
	if ((window as any)?.appInterface?.getUser) {
		const userData = (window as any)?.appInterface?.getUser();
		return JSON.parse(userData) as AppUserData;
	}
	// Return a default value if getUser is not available
	return null;
};

export const openCustomTab = (redirectURL: string) => {
	if ((window as any)?.appInterface) {
		(window as any)?.appInterface?.openCustomTab(redirectURL);
	}
};
export const openShare = (shareData: string) => {
	if ((window as any)?.appInterface) {
		(window as any)?.appInterface?.share(shareData);
	}
};
export const openNewWebView = (redirectURL: string) => {
	if ((window as any)?.appInterface) {
		(window as any)?.appInterface?.loadNewWebView(redirectURL);
	}
};

export const getAppVersion = () => {
	if ((window as any)?.appInterface) {
		return (window as any)?.appInterface?.getPrefsInt("appVersion");
	}
	return null;
};
export const APP_INTERACTION_TYPES = {
	BACK_PRESS: "BACK_PRESS",
};

export const getAppLocalData = (key: string): string | null => {
	if ((window as any)?.appInterface) {
		return (window as any)?.appInterface?.getPrefsString(key);
	}
	return null;
};

export const setAppLocalData = (key: string, value: string): void => {
	if ((window as any)?.appInterface) {
		(window as any)?.appInterface?.setPrefsString(key, value);
	}
};
