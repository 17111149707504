const UrbanCompanyNew = () => (
	<svg
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		height="32"
		viewBox="0 0 800 246"
	>
		<path d="M27.7,12.9c3.7-.8,7.5-.5,11.3-.6,45.9,0,91.7,0,137.6,0,14.6.3,27,13.6,26.8,28.1,0,46.9,0,93.8,0,140.7.6,14.8-12.1,28.4-26.9,28.8-47.5.1-95.1,0-142.6,0-14.7.4-28.1-12.4-28.3-27.1-.1-47.5,0-95,0-142.6-.2-12.9,9.6-24.9,22.2-27.5M36.2,78.1c0,14-.1,27.9,0,41.9.5,10.4,8.1,19.9,18.2,22.5,9.5,2.5,20.1,2.4,29.2-1.8,8.2-3.8,13.3-12.6,13.5-21.5.1-13.7,0-27.4,0-41.1h-16.8c-.2,13.6.4,27.3-.3,40.9-1.4,11.6-19,14.1-25,4.8-2.3-3.3-2.2-7.4-2.2-11.2,0-11.5,0-23,0-34.5,0,0-16.8,0-16.8,0ZM116.3,93.3c-7.5,12.2-6.4,29.3,3.1,40,9.7,10.9,26.9,13.8,39.9,7.6,6-2.8,10-8.2,13.4-13.7-4.6-2.2-9.1-4.5-13.7-6.8-2,2.6-3.7,5.7-6.8,7.1-7.8,4.8-18.9.8-22.5-7.4-5-9.7-.7-23.7,10.4-26.7,7.5-2.7,15.4,2,18.9,8.6,4.5-2.4,9.1-4.6,13.7-6.9-3.3-6.3-8.3-11.8-14.9-14.7-14.5-5.7-33.3-1-41.5,12.8" />
		<path d="M388.8,104.8V16.7c5,0,10.1,0,15.1,0v33c11.8-14.7,36.5-10.9,45.8,4.6,9.8,14.7,6.4,37.5-9,47.1-11.4,7.8-28.5,6.4-37.2-4.9,0,2.8,0,5.6.1,8.3,0,0-14.8,0-14.8,0ZM413.2,56.1c-7.8,4-11.5,13.6-9.5,21.9,1.7,10.7,13.9,17.1,23.9,13.4,11.7-3.5,15.5-19.3,9.1-29-4.7-7.9-15.4-10.2-23.5-6.4" />
		<path d="M264,19.1h16c0,18.1,0,36.1,0,54.2-.1,6.3,2.6,13,8.4,15.9,6.4,2.6,14.5,2.4,19.9-2.2,4.4-3.8,5.5-9.8,5.5-15.4,0-17.5,0-35,0-52.6h16c0,18.6,0,37.1,0,55.7,0,10.3-4.4,21.3-13.7,26.5-14.5,8.4-36.4,6.7-46.5-7.8-4.1-5.9-5.5-13.2-5.5-20.3,0-18,0-36.1,0-54.1" />
		<path d="M356.3,50.2c3.9-6.3,10.9-10.7,18.5-9.9,2,.6,5.3-.2,6.4,1.8,0,4.6-.7,9.1-1,13.7-5.1-1.1-10.7-1.8-15.4,1-5.6,3.3-7.8,10.1-7.8,16.3,0,10.6,0,21.2,0,31.7h-15.1c0-21,0-41.9,0-62.9,4.9,0,9.7,0,14.6,0,0,2.8,0,5.5-.1,8.3" />
		<path d="M472,49c11.2-11.3,31.4-11.6,42.2.5,0-2.5,0-5.1,0-7.6,4.9,0,9.7,0,14.6,0v62.9c-4.9,0-9.7,0-14.6,0,0-2.6,0-5.1,0-7.7-11.3,13.6-34.5,11.5-44.6-2.4-10.1-13.2-9.5-33.8,2.4-45.7M487.1,56.4c-6.1,3.2-9.6,10.1-9.5,16.9-.6,10.8,9.4,20.7,20.3,19.1,12.6-.5,20.1-15.6,15.3-26.7-3.5-10.2-16.9-14.6-26.1-9.3" />
		<path d="M556.5,50.5c8.1-13.5,30-13.6,39.2-1.3,3.8,4.9,5.3,11.2,5.3,17.3,0,12.8,0,25.5,0,38.3h-15.2c0-11.1,0-22.2,0-33.3-.2-4.3-.5-8.9-3.2-12.4-4.1-5.8-12.6-6.4-18.4-3-4.4,3-7.4,8.1-7.4,13.6-.1,11.7,0,23.4,0,35.1h-15.2c0-21,0-41.9,0-62.9,5,0,10,0,15,0,0,2.9,0,5.8,0,8.7" />
		<path d="M274.9,132.2c18.8-17.8,52.8-14.3,67.1,7.4-2.7,2.2-5.4,4.4-8.2,6.5-4.7-6-10.8-11-18.2-13.2-11.6-3.8-25.4-1-34.1,7.8-12,11.7-13.4,32.4-3.3,45.6,10.4,13.8,31.7,16.3,45.9,7.1,4-2.4,7.1-6,10.4-9.2,2.5,2.3,5,4.5,7.6,6.7-6.2,7.4-14,13.6-23.4,16.3-16.8,5.3-36.9.5-48.3-13.2-14.8-17.4-13-46.5,4.3-61.6" />
		<path d="M432.4,154.7c7.7-14.3,31.3-13.9,38.2.9,7.5-11.1,23.4-14.5,35.1-8.5,8.2,4.3,12.2,13.9,12.1,22.9,0,12.6,0,25.2,0,37.9-3.4,0-6.8,0-10.2,0,0-12.1,0-24.2,0-36.4,0-5.9-2-12.4-7.4-15.6-7.3-4.1-18-1.7-22,6-2.4,3.9-2.9,8.5-2.8,13,0,11,0,22,0,33-3.4,0-6.8,0-10.3,0,0-11.6,0-23.1,0-34.7-.1-5.6-1.2-11.8-5.5-15.8-7.2-6.2-19.8-3.8-24.2,4.6-2.3,3.9-2.9,8.5-2.9,12.9,0,11,0,22,0,33-3.4,0-6.8,0-10.2,0v-61.9c3.3,0,6.6,0,10,0,0,3,0,5.9,0,8.8" />
		<path d="M684.5,154.4c8.1-12.9,29.2-13.5,38.6-1.8,4.7,5.1,5.7,12.3,5.7,18.9,0,12.1,0,24.2,0,36.3h-10.5c0-11.5,0-23.1,0-34.6,0-5.5-1.3-11.4-5.4-15.3-5.3-5.1-14.1-5.2-20.2-1.4-5.5,3.5-7.9,10.2-8,16.5,0,11.6,0,23.2,0,34.8h-10.2c0-20.6,0-41.2,0-61.9h10c0,2.8,0,5.6,0,8.4" />
		<path d="M371.9,145.7c10.1-2.9,21.7-1.1,29.9,5.6,14,10.8,16,33.4,4.5,46.7-11.2,14.1-34.4,14.9-47.3,2.7-10.2-9.6-12.8-25.9-6.8-38.4,3.7-8.1,11.2-14.2,19.7-16.6M375.6,154.7c-9.5,2.7-16.4,12.1-16.2,22-.5,12,9.8,23.5,22,23.2,12.3.2,22.6-11.4,22-23.5.4-13.9-14.1-26.2-27.8-21.7" />
		<path d="M528.5,145.9h10.3v9.2c5.6-6.7,14.1-11.3,23-10.8,13.2,0,25.6,9.7,28.9,22.5,4.7,14.5-1.5,32.6-15.8,39.2-11.8,6.3-27.9,3.5-36.2-7.3,0,11.5,0,23,0,34.5-3.4,0-6.8,0-10.2,0,0-29.1,0-58.1,0-87.2M554.3,154.6c-15.2,3.5-20.5,24.2-11.9,36.2,6.9,10.9,24.3,12.1,33,2.9,8.8-9,8.4-25.1-.3-34-5.5-5.1-13.6-6.9-20.8-5.1" />
		<path d="M603,159.1c6.4-11,20-16.8,32.4-14.2,6.5,1.1,11.9,5.1,16.2,9.9,0-3,0-5.9,0-8.9,3.3,0,6.6,0,10,0v61.9c-3.4,0-6.7,0-10,0,0-2.9,0-5.9,0-8.8-10.1,13-31,13.6-42.8,2.4-11.3-10.6-13.6-29.1-5.9-42.3M624.9,154.5c-16,3.8-20.8,26.3-10.5,38,7.5,9.5,23.3,9.7,31.6,1.2,8.8-9,8.6-25.1-.3-34-5.5-5.1-13.6-6.9-20.8-5.1" />
		<path d="M732.9,145.9h11.9c6.6,15.7,13.1,31.5,19.6,47.2,6-15.6,11.4-31.5,17.1-47.2,3.8,0,7.6,0,11.4,0-8.3,22.1-16.7,44.2-25,66.3-2.5,6.4-5,13.1-10.3,17.7-5.7,4.9-14.3,5.1-20.9,2.2-.4-3.1.4-6.3.7-9.4,4.1,1.3,9,2.8,13,.3,5.3-3.5,7-10.1,9.4-15.7-9.1-20.4-17.8-41-26.9-61.4" />
	</svg>
);
export default UrbanCompanyNew;
