/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Modal as MaterialUiModal, useMediaQuery } from "@material-ui/core";

interface ModalProps {
	children: ReactNode;
	open: boolean;
	handleClose?: () => void;
	height?: number | string;
	maxWidth?: number | string;
	maxHeight?: number | string;
	ariaLabel?: string;
	ariaDescribe?: string;
	disableAutoFocus?: boolean;
	disableBackdropClick?: boolean;
	disableEscapeKeyDown?: boolean;
	disableEnforceFocus?: boolean;
	paddingSpacing?: number[];
	noBorderRadius?: boolean;
	noBorder?: boolean;
	borderRadius?: number;
	drawerViewForMobile?: boolean;
	smallDeviceProps?: Record<string, any>;

	hideBackdrop?: boolean;
	rootFlexbox?: boolean;
	zIndex?: number;
}

const useStyles = makeStyles<Theme, ModalProps>((theme) => ({
	paper: {
		position: "absolute",
		width: "100%",
		maxWidth: ({ maxWidth }) => maxWidth,
		maxHeight: ({ maxHeight }) => maxHeight,
		overflowY: "visible",
		overflowX: "visible",
		backgroundColor: theme.palette.background.paper,
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: ({ noBorderRadius, borderRadius }) =>
			noBorderRadius ? 0 : `${borderRadius}px`,
		boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.04)",
		border: ({ noBorder }) => (noBorder ? "none" : "solid 1px #ededed"),
		height: ({ height = "auto" }) => height,
		"&:focus": {
			outline: "none",
		},
		fontFamily: `"Inter", sans-serif`,
		display: ({ rootFlexbox }) => (rootFlexbox ? "flex" : "block"),
		flexDirection: ({ rootFlexbox }) => (rootFlexbox ? "column" : undefined),
	},
	drawerViewStyle: {
		"@media (max-width:786px)": {
			borderRadius: ({ noBorderRadius }) =>
				noBorderRadius ? 0 : "8px 8px 0 0 !important",
			top: "auto",
			bottom: 0,
			transform: "translate(-50%, 0)",
		},
	},
}));

const Modal: React.FC<ModalProps> = ({
	children,
	open,
	handleClose = () => null,
	maxWidth,
	maxHeight,
	height,
	ariaLabel,
	ariaDescribe,
	disableAutoFocus = false,
	disableBackdropClick,
	disableEscapeKeyDown,
	disableEnforceFocus,
	paddingSpacing = [2, 4, 3],
	noBorderRadius,
	noBorder,
	borderRadius = 8,
	drawerViewForMobile = false,
	smallDeviceProps = {},
	hideBackdrop = false,
	rootFlexbox = false,
	zIndex = 1300,
}) => {
	const isDesktopDevice = useMediaQuery("(min-width:768px)");

	const mobileStyles = useStyles({
		height,
		maxWidth,
		maxHeight,
		paddingSpacing,
		noBorderRadius,
		noBorder,
		borderRadius,
		drawerViewForMobile,
		...smallDeviceProps,
		children: "",
		open: false,
		rootFlexbox,
	});

	const desktopStyles = useStyles({
		height,
		maxWidth,
		maxHeight,
		paddingSpacing,
		noBorderRadius,
		noBorder,
		borderRadius,
		drawerViewForMobile,
		children: "",
		open: false,
		rootFlexbox,
	});

	const { paper, drawerViewStyle } = isDesktopDevice ? desktopStyles : mobileStyles;

	const handleCloseWrapper = (event: React.SyntheticEvent, reason: string) => {
		if (disableBackdropClick && reason === "backdropClick") {
			return false;
		}

		if (disableEscapeKeyDown && reason === "escapeKeyDown") {
			return false;
		}

		if (typeof handleClose === "function") {
			handleClose();
		}
	};

	return (
		<MaterialUiModal
			aria-labelledby={ariaLabel}
			aria-describedby={ariaDescribe}
			open={open}
			onClose={handleCloseWrapper}
			disableAutoFocus={disableAutoFocus}
			disableEnforceFocus={disableEnforceFocus}
			hideBackdrop={hideBackdrop}
			style={{ zIndex }}
		>
			<div className={`${paper} ${drawerViewForMobile ? drawerViewStyle : ""}`}>
				{children}
			</div>
		</MaterialUiModal>
	);
};

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ariaLabel: PropTypes.string,
	ariaDescribe: PropTypes.string,
	handleClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
	disableAutoFocus: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	disableEscapeKeyDown: PropTypes.bool,
	disableEnforceFocus: PropTypes.bool,
	drawerViewForMobile: PropTypes.bool,
};

Modal.defaultProps = {
	// maxWidth: 400,
	maxHeight: "100vh",
	ariaLabel: "modal-title",
	ariaDescribe: "modal-description",
	disableAutoFocus: false,
	disableBackdropClick: false,
	disableEscapeKeyDown: false,
	disableEnforceFocus: false,
	drawerViewForMobile: false,
};

export default React.memo(Modal);
